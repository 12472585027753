<template>
  <div>
    <div class="map_share" v-if="vxShare">
      <div class="no_vx_cl_bg">
        <img src="../assets/share.png" alt="" />
      </div>
      <div v-if="this.$route.query.locale != 'zh'">1</div>
      <div id="container" v-if="this.$route.query.locale == 'zh'">
        <baidu-map class="map" :zoom="map.zoom" @ready="run">
          <!--缩放-->
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <!--点-->
          <bm-marker
            :position="map.center"
            :dragging="map.dragging"
            animation="BMAP_ANIMATION_DROP"
          >
          </bm-marker>
        </baidu-map>
      </div>
      <div class="main">
        <div class="user_info">
          <img :src="avatarUrl" alt="" />
          <p class="user_info_identity" v-if="this.type == 1">
            {{ $t("indexShare.passenger") }}
          </p>
          <p class="user_info_identity" v-else-if="this.type == 2">
            {{ $t("indexShare.owner") }}
          </p>
          <p class="user_info_name">{{ username }}</p>
        </div>

        <div class="trip">
          <p class="trip_time">
            {{ date }}·{{ passengerNumber }}{{ $t("indexShare.people") }}·{{
              price
            }}{{ $t("indexShare.yuan") }}
          </p>
          <div v-for="(item, index) in tripList" :key="index">
            <div class="trip_start">
              <p class="trip_round"></p>
              <p class="trip_start_place">{{ item.name }}</p>
            </div>
          </div>
        </div>

        <div class="register_btn">{{ $t("indexShare.newUser") }}</div>
        <div class="openapp_btn">{{ $t("indexShare.openapp") }}</div>
      </div>
    </div>

    <div class="map_share" v-if="!vxShare">
      <div id="container">
        <baidu-map class="map" :zoom="map.zoom" @ready="run">
          <!--缩放-->
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <!--点-->
          <bm-marker
            :position="map.center"
            :dragging="map.dragging"
            animation="BMAP_ANIMATION_DROP"
          >
          </bm-marker>
        </baidu-map>
      </div>
      <div class="main">
        <div class="user_info">
          <img :src="avatarUrl" alt="" />
          <p class="user_info_identity" v-if="this.type == 1">
            {{ $t("indexShare.passenger") }}
          </p>
          <p class="user_info_identity" v-else-if="this.type == 2">
            {{ $t("indexShare.owner") }}
          </p>
          <p class="user_info_name">{{ username }}</p>
        </div>

        <div class="trip">
          <p class="trip_time">
            {{ date }}·{{ passengerNumber }}{{ $t("indexShare.people") }}·{{
              price
            }}{{ $t("indexShare.yuan") }}
          </p>
          <div v-for="(item, index) in tripList" :key="index">
            <div class="trip_start">
              <p class="trip_round"></p>
              <p class="trip_start_place">{{ item.name }}</p>
            </div>
          </div>
          <!-- <div class="trip_start" style="margin-top: 4px">
          <p class="trip_round" style="background: #7d828c"></p>
          <p class="trip_start_place" style="color: #7d828c">西二旗</p>
        </div> -->

          <!-- <div class="trip_start" style="margin-top: 4px">
          <p class="trip_round" style="background: #384057"></p>
          <p class="trip_start_place">和谐家园2区</p>
        </div> -->
        </div>

        <div class="register_btn" @click="goRegister">
          {{ $t("indexShare.newUser") }}
        </div>
        <div class="openapp_btn" @click="openApp">
          {{ $t("indexShare.openapp") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqUrl } from "../api/apiUrl";
import { request } from "../api/request";

export default {
  name: "demo",
  data() {
    return {
      vxShare: true,
      locale: true,

      map: {
        center: { lng: 116.428113, lat: 39.885959 },
        zoom: 18,
        show: true,
        dragging: true,
      },
      tripList: [],
      username: "",
      date: "",
      price: "",
      passengerNumber: "",
      avatarUrl: "",
      type: "",

      dateNew: "",
    };
  },

  methods: {
    run({ BMap, map }) {
      var nonceCode = "";
      for (var i = 0; i < 32; i++) {
        nonceCode += Math.floor(Math.random() * 10);
      }

      let md5Code = `${this.$route.query.shareUid}${
        this.$route.query.travelId
      }${this.getTimeFn()}${nonceCode}18A626260181FCF6B6B5929B5BCC3B0F7D4CF5F4`;

      const opt = {
        url: reqUrl.travalUrl,
        method: "POST",
        headers: {
          "Content-Type":
            "application/json; charset=utf-8;responseType=arraybufferimage.png",
          ts: this.dateNew,
          nonce: nonceCode,
          sign: md5(md5Code),
        },
        params: JSON.stringify({
          travelId: this.$route.query.travelId,
          shareUid: this.$route.query.shareUid,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: this.$t("indexShare.fail"),
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            localStorage.setItem(
              "userInfoDTOPhone",
              res.data.data.userInfoDTO.phone
            );
            localStorage.setItem(
              "userInfoDTOavatarUrl",
              res.data.data.userInfoDTO.avatarUrl
            );
            localStorage.setItem("userInfoDTOType", res.data.data.type);

            let formatPointListMap = [];
            res.data.data.formatPointList.map((item) => {
              if (item.lng != 0) {
                formatPointListMap.push(item);
              }
            });

            this.username = res.data.data.userInfoDTO.userName;
            var dateNow = new Date(parseInt(res.data.data.startTime) * 1000)
              .toLocaleString()
              .replace(/:\d{1,2}$/, " ");
            var date1 = new Date(parseInt(res.data.data.startTime) * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var h = date1.getHours();
            var m = date1.getMinutes();
            if (m < 10) {
              m = "0" + m;
            }

            if (h < 10) {
              h = "0" + h;
            }
            if (
              new Date(
                Number(res.data.data.startTime) * 1000
              ).toDateString() === new Date().toDateString()
            ) {
              this.date = this.$t("indexShare.today") + h + ":" + m;
            } else {
              this.date = dateNow;
            }

            this.price =
              res.data.data.type == 2
                ? res.data.data.travelFare
                : res.data.data.driverTravelFare;
            this.passengerNumber = res.data.data.userInfoDTO.passengerNumber;
            this.avatarUrl = res.data.data.userInfoDTO.avatarUrl
              ? res.data.data.userInfoDTO.avatarUrl
              : require("../assets/headimg.png");
            this.tripList = formatPointListMap;
            this.type = res.data.data.type;

            var walking = new BMap.WalkingRoute(map, {
              renderOptions: {
                autoViewport: true,
              },
            }); //创建步行实例
            map.clearOverlays(); //清除地图上所有的覆盖物

            if (this.tripList.length == 2) {
              // 鼠标缩放
              map.enableScrollWheelZoom(true);
              for (var i = 0; i < this.tripList.length; i++) {
                if (this.tripList[i + 1]) {
                  var arr1 = new BMap.Point(
                    this.tripList[i].lng,
                    this.tripList[i].lat
                  );
                  var arr2 = new BMap.Point(
                    this.tripList[i + 1].lng,
                    this.tripList[i + 1].lat
                  );

                  // 点击事件获取经纬度
                  var path = []; //本人的示例是要走规定经过的路线，所以中间有多经过点

                  path.push([this.tripList[i].lng, this.tripList[i].lat]);
                  path.push([
                    this.tripList[i + 1].lng,
                    this.tripList[i + 1].lat,
                  ]);

                  // map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);
                  for (let i = 0; i < path.length; i += 2) {
                    var walking = new BMap.WalkingRoute(map, {
                      renderOptions: {
                        map: map,
                        autoViewport: true,
                      },
                      onPolylinesSet: function (routes) {
                        let searchRoute = routes[0].getPolyline(); //导航路线
                        map.removeOverlay(searchRoute); //移除查询出来 的路线
                      },
                      onMarkersSet: function (routes) {
                        map.removeOverlay(routes[0].marker); //删除起点
                        map.removeOverlay(routes[routes.length - 1].marker); //删除终点
                      },
                    });
                    var labnull1 = new BMap.Label(this.tripList[i].name, {
                      position: arr1,
                      offset: new BMap.Size(-33, -45),
                    }); //创建3个label

                    var labnull3 = new BMap.Label(this.tripList[i + 1].name, {
                      position: arr2,
                      offset: new BMap.Size(-33, -45),
                    });

                    labnull1.setStyle({
                      color: "#000",
                      fontSize: "14px",
                      backgroundColor: "#fff",
                      border: "none",
                      padding: "10px",
                      borderRadius: "10px",
                    });

                    labnull3.setStyle({
                      color: "#000",
                      fontSize: "14px",
                      backgroundColor: "#fff",
                      border: "none",
                      padding: "10px",
                      borderRadius: "10px",
                    });

                    map.addOverlay(labnull1);
                    map.addOverlay(labnull3);

                    let _this = this;
                    var start = new BMap.Point(path[i][0], path[i][1]);
                    var end = new BMap.Point(path[i + 1][0], path[i + 1][1]);
                    walking.search(start, end);
                    walking.setSearchCompleteCallback(function () {
                      var plan = walking.getResults().getPlan(0);
                      for (let j = 0; j < plan.getNumRoutes(); j++) {
                        var pts = plan.getRoute(j).getPath();
                        var polyline = new BMap.Polyline(pts, {
                          strokeColor: "#00BA5D",
                          strokeWeight: 4,
                          strokeOpacity: 1,
                        }); //重新划路线
                        map.addOverlay(polyline);
                      }
                    });
                  }
                  map.addEventListener("click", function (e) {
                    // 点击地点获取经纬度
                    console.log(e.point.lng, e.point.lat);
                  });
                }
              }
            } else if (this.tripList.length > 2) {
              // walking.search(myP2, myP3); //第二个步行搜索/
              for (var i = 0; i < this.tripList.length; i++) {
                if (this.tripList[i + 1]) {
                  var arr1 = new BMap.Point(
                    this.tripList[i].lng,
                    this.tripList[i].lat
                  );
                  var arr2 = new BMap.Point(
                    this.tripList[i + 1].lng,
                    this.tripList[i + 1].lat
                  );
                  walking.search(arr1, arr2); //第一个步行搜索
                }
              }

              map.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
              walking.setSearchCompleteCallback(function () {
                var pts = walking.getResults().getPlan(0).getRoute(0).getPath(); //通过步行实例，获得一系列点的数组
                console.log(pts);
                var polyline = new BMap.Polyline(pts, {
                  strokeColor: "#00BA5D",
                  strokeWeight: 4,
                  strokeOpacity: 1,
                });
                map.addOverlay(polyline);
              });
            }
          }
        },
        errFunc: (err) => {
          console.log(err, "err1");
          this.$message({
            type: "error",
            message: err,
          });
        },
      };
      request(opt);
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    openApp() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      if (isAndroid) {
        window.location = `qcx://com.quchuxing.qutaxi/openwith?travelId=${
          this.$route.query.travelId
        }&type=${localStorage.getItem("userInfoDTOType")}&shareUid=${
          this.$route.query.shareUid
        }`;
        setTimeout(function () {
          window.location.href = "https://cdn.quchuxing.com/apk/quchuxing.apk"; // 超时跳转下载页
        }, 500);
      }
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        window.location.href = `flks://line?travelId=${
          this.$route.query.travelId
        }&type=${localStorage.getItem("userInfoDTOType")}&shareUid=${
          this.$route.query.shareUid
        }`;
        setTimeout(function () {
          window.location.href =
            "https://apps.apple.com/cn/app/%E8%B6%A3%E5%87%BA%E8%A1%8C-%E8%AE%A9%E9%A1%BA%E9%A3%8E%E8%BD%A6%E6%8B%BC%E8%BD%A6%E5%87%BA%E8%A1%8C%E6%9B%B4%E6%9C%89%E8%B6%A3/id1197745409"; // 超时跳转下载页
        }, 500);
      }
      return 0;
    },

    // 去注册
    goRegister() {
      this.$router.push({
        path: "/register",
        query: {
          avatarUrl: localStorage.getItem("userInfoDTOavatarUrl"),
          phone: localStorage.getItem("userInfoDTOPhone"),
          shareInviteCode: this.$route.query.shareInviteCode,
        },
      });
    },
  },

  mounted() {
    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }

    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.vxShare = true; // '微信内部'
    } else {
      this.vxShare = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.no_vx_cl_bg {
  z-index: 11;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;

  img {
    width: 258px;
    height: 209px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.map_share {
  font-family: "PingFang SC";
  font-style: normal;
  font-size: 14px;

  #container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .map {
      width: 100%;
      height: 54%;

      /deep/ .anchorBL {
        display: none;
      }

      /deep/ .BMap_Marker {
        border: 1px red solid;
      }
    }
  }
  .main {
    width: 100%;
    height: 48%;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    .user_info {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        margin: 0 16px;
      }

      .user_info_identity {
        font-weight: 600;
        color: #202226;
      }

      .user_info_name {
        color: #4e5259;
        margin-left: 4px;
      }
    }

    .trip {
      width: calc(100% - 32px);
      border-radius: 10px;
      border: 1px #ebedf2 solid;
      margin: 0 auto;
      padding-bottom: 6px;

      .trip_time {
        font-weight: 500;
        font-size: 16px;
        color: #202226;
        margin: 8px 16px 6px 16px;
      }

      .trip_round {
        width: 5px;
        height: 5px;
        background: #00ba5d;
        border-radius: 40px;
      }

      .trip_start {
        display: flex;
        align-items: center;
        margin-left: 16px;
        margin-top: 4px;

        .trip_start_place {
          font-weight: 400;
          font-size: 13px;
          color: #202226;
          margin-left: 6px;
        }
      }
    }

    .register_btn,
    .openapp_btn {
      width: calc(100% - 32px);
      height: 54px;
      background: #5778ff;
      border-radius: 80px;

      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      line-height: 54px;
      color: #fff;
    }

    .register_btn {
      margin: 16px auto;
      margin-bottom: 12px;
    }

    .openapp_btn {
      background: #f2f5fa;
      margin: 0 auto;
      color: #202226;
    }
  }
}
</style>
