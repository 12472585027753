import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import Share from "../views/share1.vue";
import Register from "../views/Register.vue";
import Ditu from "../views/ditu.vue";
import CodeRegister from "../views/codeOpenApp.vue";
import JoinGroup from "../views/banner/JoinGroup.vue";
import PledgePopUp from "../views/banner/PledgePopUp.vue";
import PledgeWorld from "../views/banner/PledgeWorld.vue";
import SignOut from "../views/banner/SignOut.vue";
import Privacy from "../views/banner/Privacy.vue";
import AgentMobile from "../views/mobileView/AgentMobile.vue";
import AgentMobileDetail from "../views/mobileView/AgentMobileDetail.vue";
import FireWealthBox from "../views/banner/FireWealthBox.vue";
import FireWealthBoxRule from "../views/banner/FireWealthBoxRule.vue";

// 预售趣宝盒
import boxWallet from "../views/boxWallet/index.vue";
import boxWalletWhatBox from "../views/boxWallet/whatBox.vue";
import boxWalletOrder from "../views/boxWallet/order/payment.vue";
import paySuccess from "../views/boxWallet/order/paySuccess.vue";
import meOrder from "../views/boxWallet/order/meOrder.vue";
import meOrderDetail from "../views/boxWallet/order/detail.vue";
import vaitPayOrder from "../views/boxWallet/order/vaitPay.vue";

import anc from "../views/boxWallet/order/1234.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/shareIndex",
    name: "Share",
    component: Share,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/ditu",
    component: Ditu,
  },
  {
    path: "/codeOpenApp",
    component: CodeRegister,
  },

  {
    path: "/JoinGroup",
    component: JoinGroup,
  },
  {
    path: "/PledgePopUp",
    component: PledgePopUp,
  },
  {
    path: "/SignOut",
    component: SignOut,
  },
  {
    path: "/Privacy",
    component: Privacy,
  },
  {
    path: "/PledgeWorld",
    component: PledgeWorld,
  },

  {
    path: "/AgentMobile", // 代理/运营-代理-移动端
    component: AgentMobile,
  },
  {
    path: "/AgentMobileDetail", // 代理/运营-代理-移动端-奖励明细
    component: AgentMobileDetail,
  },
  {
    path: "/FireWealthBox", // 火财盒
    component: FireWealthBox,
  },
  {
    path: "/FireWealthBoxRule", // 火财盒规则
    component: FireWealthBoxRule,
  },

  // 趣宝盒预售
  {
    path: "/boxWallet",
    component: boxWallet,
  },
  // 什么是趣宝盒
  {
    path: "/whatBox",
    component: boxWalletWhatBox,
  },
  // 确认订单
  {
    path: "/boxWalletOrder",
    component: boxWalletOrder,
  },
  // 支付成功
  {
    path: "/paySuccess",
    component: paySuccess,
  },
  // 我的订单
  {
    path: "/meOrder",
    component: meOrder,
  },
  // 我的订单
  {
    path: "/meOrder/detail",
    component: meOrderDetail,
  },
  // 待支付
  {
    path: "/vaitPayOrder",
    component: vaitPayOrder,
  },
  {
    path: "/1234",
    component: anc,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
