<template>
  <div class="pay_success">
    <Header></Header>

    <div class="order_true" v-if="orderListData.mallOrderInfo.orderType == 3">
      <p v-if="orderListData.payOrderInfo.orderStatus == '待发货'">
        待发货（{{ orderListData.presaleInfoDTO.deliverMonth }}月发货）
      </p>
      <p v-if="orderListData.payOrderInfo.orderStatus == '已发货'">已发货</p>
      <p v-if="orderListData.payOrderInfo.orderStatus == '已签收'">已签收</p>
      <p v-if="orderListData.payOrderInfo.orderStatus == '交易完成'">
        交易完成
      </p>
      <p v-if="orderListData.payOrderInfo.orderStatus == '交易结束'">
        交易关闭
      </p>
      <p v-if="orderListData.payOrderInfo.orderStatus == '已取消'">
        交易关闭，订单已取消
      </p>
      <p class="order_true_orderid">
        趣宝盒编号：{{ orderListData.presaleInfoDTO.serialNum }}
      </p>
    </div>

    <div
      v-if="orderListData.payOrderInfo.orderStatus == '待发货'"
      class="order_info_peo"
      style="margin: 18px 0"
    >
      <div style="display: flex; align-items: center">
        <i class="iconfont icon-position"></i>
        <div>
          <div class="order_info_peo_pos">
            <p class="order_info_peo_t1">{{ currentContact.name }}</p>
            <p class="order_info_peo_t2">{{ currentContact.tel }}</p>
          </div>
          <p class="order_info_peo_t3">{{ currentContact.address }}</p>
        </div>
      </div>
      <!-- <van-icon name="arrow" style="margin-right: 0" /> -->
    </div>

    <div
      v-if="
        orderListData.payOrderInfo.orderStatus == '已发货' ||
        orderListData.payOrderInfo.orderStatus == '已签收' ||
        orderListData.payOrderInfo.orderStatus == '交易完成'
      "
      class="edit_order_info_peo"
    >
      <div class="order_info_peo" @click="handleShowExp" style="margin: 10px 0">
        <p>快递单号：{{ orderListData.mallOrderInfo.expressNumber }}</p>
        <van-icon name="arrow" style="margin-right: 0" />
      </div>
    </div>

    <van-popup v-model="showExpress" position="bottom" closeable round>
      <div class="order-peo_exp">
        <p class="order-peo_exp_border">
          快递名称：{{ orderListData.mallOrderInfo.expressName }}
        </p>
        <p>
          快递单号：{{ orderListData.mallOrderInfo.expressNumber }}
          <i
            v-if="orderListData.mallOrderInfo.expressNumber"
            class="iconfont icon-fuzhi"
            v-clipboard:copy="`${orderListData.mallOrderInfo.expressNumber}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          ></i>
        </p>
      </div>
      <div class="order_info_peo">
        <div style="display: flex; align-items: center">
          <i class="iconfont icon-position"></i>
          <div v-if="orderListData.addressInfo">
            <div class="order_info_peo_pos">
              <p class="order_info_peo_t1">
                {{ orderListData.addressInfo.contacts }}
              </p>
              <p class="order_info_peo_t2">
                {{ orderListData.addressInfo.number }}
              </p>
            </div>
            <p class="order_info_peo_t3">
              {{ orderListData.addressInfo.address }}
            </p>
          </div>
        </div>
      </div>
    </van-popup>

    <div class="mall_info">
      <div class="mall_info_box">
        <div>
          <div class="order_img">
            <img :src="orderListData.skuInfo.iconUrl" alt="" />
          </div>
          <p class="order_img_txt">{{ orderListData.skuInfo.skuAttr }}</p>
        </div>
        <div class="mall_info_count">
          <p>${{ orderListData.skuInfo.skuPrice }}</p>
          <p class="count_style">×{{ orderListData.mallOrderInfo.goodsNum }}</p>
        </div>
      </div>

      <div>
        <p class="font_txt1">商品总价</p>
        <p>${{ orderListData.goodsInfo.goodsPrice }}</p>
      </div>
      <div>
        <p class="font_txt1">CPLE积分抵扣 (5%)</p>
        <p>
          -$
          {{
            orderListData.payOrderInfo.payType == "95% usdt+5%cple积分"
              ? orderListData.payOrderInfo.p95Amount
              : 0
          }}
        </p>
      </div>
      <div style="margin-bottom: 10px">
        <p class="font_txt1">运费</p>
        <p>$0.00</p>
      </div>
      <div class="mall_info_all">
        <p>实付</p>
        <p>
          ${{
            orderListData.payOrderInfo.payType == "USDT"
              ? orderListData.payOrderInfo.amount
              : orderListData.payOrderInfo.payType == "95% usdt+5%cple积分"
              ? orderListData.payOrderInfo.p95Amount
              : 0
          }}
        </p>
      </div>
    </div>

    <div class="mall_info">
      <div class="font_txt">
        <p class="font_txt1">订单编号</p>
        <p>{{ orderListData.mallOrderInfo.orderId }}</p>
        <i
          class="iconfont icon-fuzhi"
          v-clipboard:copy="`${orderListData.mallOrderInfo.orderId}`"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
        </i>
      </div>
      <div class="font_txt">
        <p class="font_txt1">下单时间</p>
        <p>{{ orderListData.mallOrderInfo.createTime }}</p>
      </div>
      <div class="font_txt">
        <p class="font_txt1">付款时间</p>
        <p>{{ orderListData.payOrderInfo.payTime }}</p>
      </div>
      <div
        class="font_txt"
        v-if="orderListData.payOrderInfo.orderStatus == '已取消'"
      >
        <p class="font_txt1">取消时间</p>
        <p>{{ orderListData.mallOrderInfo.cancelTime }}</p>
      </div>
      <div
        class="font_txt"
        v-if="orderListData.payOrderInfo.orderStatus == '已签收'"
      >
        <p class="font_txt1">签收时间</p>
        <p>{{ orderListData.mallOrderInfo.confirmTime }}</p>
      </div>
      <div class="font_txt">
        <p class="font_txt1">支付方式</p>
        <p>{{ orderListData.payOrderInfo.payType }}</p>
      </div>
      <div class="font_txt">
        <p class="font_txt1">邀请码</p>
        <p>{{ orderListData.presaleInfoDTO.inviteCode }}</p>
      </div>
    </div>

    <div
      class="pay_success_b"
      v-if="orderListData.payOrderInfo.orderStatus == '待发货'"
      @click="showCancelOrder = true"
    >
      取消订单
    </div>

    <van-popup v-model="showCancelOrder" closeable round>
      <h2>请选择您要取消订单的原因</h2>
      <van-picker
        title="原因"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showCancelOrder = false"
      />
    </van-popup>

    <div
      class="pay_call"
      v-if="orderListData.payOrderInfo.orderStatus == '待发货'"
      style="margin: 60px auto"
      @click="handleService"
    >
      联系客服
    </div>
    <div
      class="pay_service_btn"
      v-if="orderListData.payOrderInfo.orderStatus !== '待发货'"
    >
      <!-- <div class="pay_call">查看物流</div> -->
      <div class="pay_call" @click="handleService">联系客服</div>
      <img
        src="../../../assets/boxWallet/btn2.png"
        alt=""
        @click="handleTrueReceive"
      />
    </div>

    <van-popup v-model="showService" closeable round>
      <h2>联系客服</h2>
      <p>请下载趣出行App</p>
      <img src="../../../assets/boxWallet/logo4.png" alt="" />
      <div class="pay_call" style="margin: 0 auto" @click="handleDownLoad">
        跳转下载链接
      </div>
    </van-popup>

    <van-popup v-model="showReceive" closeable round>
      <h2>确认收货</h2>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        "
      >
        <div class="receive_cancel" @click="showReceive = false">取消</div>
        <img
          style="width: 145px; height: 56px; margin: 0"
          src="../../../assets/boxWallet/true.png"
          alt=""
          @click="handleSubmitReceive"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import Header from "../header.vue";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { format_time_date_time2 } from "@/util/funBusiness";
import md5 from "js-md5";

export default {
  components: { Header },
  inject: ["reload"],
  data() {
    return {
      message: "",
      messageNum: "",
      currentContact: {
        name: "无",
        tel: "无",
        address: "无",
      },

      showExpress: false,
      showService: false,
      showReceive: false,
      showCancelOrder: false,

      columns: ["信息错误", "余额不足", "支付问题", "不再需要", "其他"],

      orderListData: {},
      dateNew: "",
    };
  },

  mounted() {
    var num = "";
    for (var i = 0; i < 32; i++) {
      num += Math.floor(Math.random() * 10);
    }
    let md5Code = `${
      this.$route.query.orderId
    }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
    console.log(md5Code);

    const opt = {
      url: reqUrl.mallOrderDetail,
      method: "POST",
      headers: {
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        wallet: localStorage.getItem("userName"),
        token: localStorage.getItem("token"),
      },
      params: {
        orderId: this.$route.query.orderId * 1,
      },
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.orderListData = res.data.data;
          this.orderListData.mallOrderInfo.createTime = format_time_date_time2(
            this.orderListData.mallOrderInfo.createTime
          );
          this.orderListData.payOrderInfo.payTime = format_time_date_time2(
            this.orderListData.payOrderInfo.payTime
          );
          this.orderListData.mallOrderInfo.cancelTime = format_time_date_time2(
            this.orderListData.mallOrderInfo.cancelTime
          );
          this.orderListData.mallOrderInfo.confirmTime = format_time_date_time2(
            this.orderListData.mallOrderInfo.confirmTime
          );

          this.currentContact.name = this.orderListData.addressInfo.contacts;
          this.currentContact.tel = this.orderListData.addressInfo.number;
          this.currentContact.address = this.orderListData.addressInfo.address;

          if (this.orderListData.payOrderInfo.payType == 1) {
            this.orderListData.payOrderInfo.payType = "余额账户";
          } else if (this.orderListData.payOrderInfo.payType == 2) {
            this.orderListData.payOrderInfo.payType = "CPLE积分账户";
          } else if (this.orderListData.payOrderInfo.payType == 3) {
            this.orderListData.payOrderInfo.payType = "支付宝账户";
          } else if (this.orderListData.payOrderInfo.payType == 4) {
            this.orderListData.payOrderInfo.payType = "微信账户";
          } else if (this.orderListData.payOrderInfo.payType == 5) {
            this.orderListData.payOrderInfo.payType = "京东账户";
          } else if (this.orderListData.payOrderInfo.payType == 6) {
            this.orderListData.payOrderInfo.payType = "转出额度";
          } else if (this.orderListData.payOrderInfo.payType == 7) {
            this.orderListData.payOrderInfo.payType = "USDT";
          } else if (this.orderListData.payOrderInfo.payType == 8) {
            this.orderListData.payOrderInfo.payType = "链上CPLE TOKEN";
          } else if (this.orderListData.payOrderInfo.payType == 9) {
            this.orderListData.payOrderInfo.payType = "数字人民币";
          } else if (this.orderListData.payOrderInfo.payType == 10) {
            this.orderListData.payOrderInfo.payType = "95%余额+5%cple积分";
          } else if (this.orderListData.payOrderInfo.payType == 11) {
            this.orderListData.payOrderInfo.payType = "95% usdt+5%cple积分";
          }

          if (this.orderListData.mallOrderInfo.isCancel) {
            this.orderListData.payOrderInfo.orderStatus = "已取消";
          } else if (this.orderListData.mallOrderInfo.isPaid == false) {
            this.orderListData.payOrderInfo.orderStatus = "等待买家付款";
          } else if (
            this.orderListData.mallOrderInfo.isPaid == true &&
            this.orderListData.mallOrderInfo.isShip == false
          ) {
            this.orderListData.payOrderInfo.orderStatus = "待发货";
          } else if (
            this.orderListData.mallOrderInfo.isPaid == true &&
            this.orderListData.mallOrderInfo.isShip == true &&
            this.orderListData.mallOrderInfo.isConfirm == false
          ) {
            this.orderListData.payOrderInfo.orderStatus = "已发货";
          } else if (
            this.orderListData.mallOrderInfo.isPaid == true &&
            this.orderListData.mallOrderInfo.isShip == true &&
            this.orderListData.mallOrderInfo.isConfirm == true
          ) {
            this.orderListData.payOrderInfo.orderStatus = "已签收";
          }
          if (this.orderListData.payOrderInfo.orderStatus == 3) {
            this.orderListData.payOrderInfo.orderStatus = "交易结束";
          }
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    onCopy() {
      // this.$Message.success(this.$t("pledgePopup.copySuccess"));
      this.$Message.success("复制成功");
    },
    onError() {
      // this.$Message.error(this.$t("pledgePopup.copyFail"));
      this.$Message.error("复制失败");
    },

    handleShowExp() {
      this.showExpress = true;
    },

    handleService() {
      this.showService = true;
    },

    handleDownLoad() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.location.href = "https://cdn.quchuxing.com/apk/quchuxing.apk"; // 超时跳转下载页
      } else if (isiOS) {
        window.location.href =
          "https://apps.apple.com/cn/app/%E8%B6%A3%E5%87%BA%E8%A1%8C-%E8%AE%A9%E9%A1%BA%E9%A3%8E%E8%BD%A6%E6%8B%BC%E8%BD%A6%E5%87%BA%E8%A1%8C%E6%9B%B4%E6%9C%89%E8%B6%A3/id1197745409"; // 超时跳转下载页
      }
    },

    handleTrueReceive() {
      this.showReceive = true;
    },

    onConfirm(value, index) {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let md5Code = `${index + 1}${
        this.$route.query.orderId * 1
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
      console.log(md5Code);

      const opt = {
        url: reqUrl.mallOrderCancelOrder,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          wallet: localStorage.getItem("userName"),
          token: localStorage.getItem("token"),
        },
        params: {
          cancelEnumType: index + 1,
          orderId: this.$route.query.orderId * 1,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.$Message.success("取消订单成功");
            this.refresh();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            this.showCancelOrder = false;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleSubmitReceive() {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let md5Code = `${
        this.$route.query.orderId
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
      console.log(md5Code);

      const opt = {
        url: reqUrl.mallOrderHasReceived,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          wallet: localStorage.getItem("userName"),
          token: localStorage.getItem("token"),
        },
        params: {
          orderId: this.$route.query.orderId,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.$Message.success("确认收货成功");
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            this.showReceive = false;
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.font_txt1 {
  color: #ffffffb2;
}

.font_txt {
  justify-content: flex-start !important;

  .font_txt1 {
    width: 72px;
  }

  i {
    margin-left: 14px;
    color: #ffffffb2;
    font-size: 19px;
  }

  p {
    font-size: 14px !important;
  }
}

.bottom {
  margin-top: 20px;
}

.edit_order_info_peo {
  .order_info_peo {
    background: unset;
    border: 1px solid #ffffff26;
    height: 50px;
    border-radius: 16px !important;
    font-size: 15px;
  }
}

.pay_success_b {
  width: 100% !important;
  border: 1px solid #ffffff26 !important;
  border-radius: 16px !important;
}

.order-peo_exp {
  margin: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ffffff26;
  border-radius: 16px !important;
  height: 100px;
  padding: 0 15px;
  font-size: 14px;

  .order-peo_exp_border {
    border-bottom: 1px solid #ffffff26;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.order_info_peo {
  border: 1px solid #ffffff26;
  margin: 18px;
}

.van-popup--center {
  width: 80%;
  background: #1d1f29;
  padding-bottom: 30px;

  h2 {
    text-align: center;
    margin: 33px 33px 15px 33px;
  }

  p {
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  img {
    width: 120px;
    height: 120px;
    margin: 20px auto;
    display: flex;
  }

  .receive_cancel {
    background: #dbe9ff0d;
    font-size: 17px;
    font-weight: 500;
    width: 145px;
    height: 56px;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
  }
}

.order_true_orderid {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffffb2 !important;
  margin-top: 10px !important;
}
</style>

<style>
.ivu-message {
  z-index: 11111 !important;
}

.el-notification {
  z-index: 11111 !important;
}
</style>
