<template>
  <div class="order_index">
    <Header></Header>

    <div class="order_true">
      <p>确认订单（{{ payData.deliverMsg }}）</p>
      <div>
        <div class="mall_info_box" v-if="skuType">
          <div style="display: flex; align-items: center">
            <div class="order_img" v-if="skuData">
              <img :src="skuData.selectedSkuComb.imgUrl" alt="" />
            </div>
            <p class="order_img_txt">{{ skuData.selectedSkuComb.name }}</p>
          </div>
          <div class="mall_info_count">
            <p>${{ skuData.selectedSkuComb.price }}</p>
            <p class="count_style">×{{ skuData.selectedNum }}</p>
          </div>
        </div>

        <div class="order_sku_list" @click="showSku = true">
          <div class="order_sku">请选择商品规格</div>
        </div>
        <van-sku
          v-model="showSku"
          :sku="sku"
          :goods="goods"
          :goods-id="skuData.goodsId"
          @buy-clicked="onBuyClick"
          :show-add-cart-btn="false"
          buy-text="确认"
        >
          <!-- 自定义 sku-header-price -->
          <template #sku-header-price="sku">
            <div class="van-sku__goods-price">
              <span class="van-sku__price-num">{{ sku.price }}</span>
            </div>
          </template>
        </van-sku>
      </div>

      <div class="order_info_peo" @click="handleEditAddress">
        <div style="display: flex; align-items: center">
          <i class="iconfont icon-position"></i>
          <div>
            <div class="order_info_peo_pos">
              <p class="order_info_peo_t1">{{ currentContact.name }}</p>
              <p class="order_info_peo_t2">{{ currentContact.tel }}</p>
            </div>
            <p class="order_info_peo_t3">{{ currentContact.address }}</p>
          </div>
        </div>
        <van-icon name="arrow" style="margin-right: 0" />
      </div>
      <div class="order_info_code">
        <el-input placeholder="请输入邀请码" v-model="code"></el-input>
      </div>

      <van-popup v-model="showEditAdr" position="bottom" closeable round>
        <van-address-list
          v-model="chosenAddressId"
          :list="list"
          default-tag-text="默认"
          @add="onAdd"
          @edit="onEdit"
          @select="handleSubAdr"
          @change-area="onChangeDetail()"
        >
          <i
            v-if="list.length <= 0"
            class="iconfont icon-zanwushuju"
            style="
              text-align: center;
              display: block;
              line-height: 350px;
              font-size: 80px;
            "
          ></i>
        </van-address-list>
      </van-popup>

      <van-popup v-model="showAddAdr" position="bottom" closeable round>
        <van-address-edit
          :area-list="areaList"
          show-delete
          show-search-result
          :search-result="searchResult"
          :area-columns-placeholder="['请选择', '请选择', '请选择']"
          @save="onSave"
          @delete="onDelete"
        />
      </van-popup>

      <van-popup v-model="showEdit" position="bottom" closeable round>
        <van-address-edit
          :area-list="areaList"
          show-delete
          show-set-default
          show-search-result
          :search-result="searchResult"
          :area-columns-placeholder="['请选择', '请选择', '请选择']"
          @save="onSaveEdit"
          @delete="onDelete"
          :address-info="infoData"
        />
      </van-popup>

      <div class="mall_txt">
        <p>使用 <span>Solflare</span> 支付</p>
      </div>

      <div class="pay_order">
        <img src="@/assets/boxWallet/buy.png" @click="handleGoBuy" alt="" />
      </div>

      <Bottom></Bottom>
    </div>
  </div>
</template>

<script>
import Header from "../header.vue";
import Bottom from "../bottom.vue";
import { areaList } from "@vant/area-data";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import md5 from "js-md5";

export default {
  components: { Header, Bottom },
  inject: ["reload"],
  data() {
    return {
      payData: {},
      value: 1,

      currentContact: {
        name: "请输入姓名",
        tel: "请输入手机号",
        address: "请输入地址",
      },

      showEditAdr: false,
      showAddAdr: false,
      showEdit: false,
      chosenAddressId: "1",
      list: [],

      areaList,
      searchResult: [],
      infoData: {},

      code: "",

      showSku: false,
      sku: {
        tree: [],
        list: [],
        price: "", // skuPrice
        stock_num: 0, // 商品总库存
      },
      goods: {
        picture: "",
      },
      skuData: {
        // 商品 id
        goodsId: "1",
        // 选择的商品数量
        selectedNum: 1,
        // 选择的 sku 组合
        selectedSkuComb: {},
      },

      skuType: false,
      dateNew: "",
    };
  },

  mounted() {
    var num = "";
    for (var i = 0; i < 32; i++) {
      num += Math.floor(Math.random() * 10);
    }

    let md5Code = `${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
    let md5Code2 = `${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
    let md5Code3 = `${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
    console.log(md5Code);

    // 获取默认地址
    const optDefaultAdr = {
      url: reqUrl.mallGetDefaultAddress,
      method: "POST",
      headers: {
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code),
        wallet: localStorage.getItem("userName"),
        token: localStorage.getItem("token"),
      },
      params: {},
      resFunc: (res) => {
        if (res.data.code == 0) {
          if (res.data.data != null) {
            this.currentContact.name = res.data.data.contacts;
            this.currentContact.tel = res.data.data.number;
            this.currentContact.address = res.data.data.address;
            this.chosenAddressId = res.data.data.addressId;
          }
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optDefaultAdr);

    // 获取地址列表
    const optAddress = {
      url: reqUrl.mallListAddress,
      method: "POST",
      headers: {
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code2),
        wallet: localStorage.getItem("userName"),
        token: localStorage.getItem("token"),
      },
      params: {},
      resFunc: (res) => {
        if (res.data.code == 0) {
          res.data.data.list.forEach((item, i) => {
            this.list.push({
              id: item.addressId,
              name: item.contacts,
              tel: item.number,
              address: item.province + item.city + item.district + item.address,
              isDefault: item.isDefault,
              userId: item.userId,
            });
            //默认选择
            // if (item.is_default == 1) {
            //   this.active_id = item.id;
            // }
          });
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optAddress);

    // 商品详情
    const optOrder = {
      url: reqUrl.mallOrderGoodsDetail,
      method: "POST",
      headers: {
        nonce: num,
        ts: this.dateNew,
        sign: md5(md5Code3),
        wallet: localStorage.getItem("userName"),
        token: localStorage.getItem("token"),
      },
      params: {},
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.payData = res.data.data;
          console.log(this.payData, "payData");

          this.goods.picture = res.data.data.detailDTO.imageUrlList;

          // 遍历原始的skuList数据，构建规格类目和规格值的映射关系
          const skuMap = {};
          const skuKeyList = [];

          // 遍历原始的skuList数据，构建规格类目和规格值的映射关系
          this.payData.detailDTO.skuList.forEach((sku) => {
            const key = sku.skuAttr;
            if (!skuMap[key]) {
              skuMap[key] = [];
            }
            skuMap[key].push({
              id: sku.skuId,
              name: sku.skuAttr,
              imgUrl: sku.iconUrl,
              previewImgUrl: sku.iconUrl,
              stock: sku.skuStock,
              price: sku.skuPrice,
            });
            skuKeyList.push(key);
          });

          // 构建规格类目树

          const treeSku = [];
          for (const key in skuMap) {
            treeSku.push(skuMap[key][0]);
          }

          const tree = [];
          tree.push({
            k: "规格", // 规格名
            k_s: "s1", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: treeSku, // 规格值列表
            largeImageMode: true, // 是否展示大图模式
          });

          this.sku.tree = tree;

          // 构建sku组合列表
          const list = [];
          for (const skuKey of skuKeyList) {
            const skuValues = skuMap[skuKey];
            for (const skuValue of skuValues) {
              list.push({
                id: skuValue.id, // skuId
                s1: skuValue.id, // 规格类目 k_s 的值，对应规格值 id
                price: skuValue.price * 100, // skuPrice
                stock_num: skuValue.stock, // skuStock
                imgUrl: skuValue.imgUrl,
                name: skuValue.name,
              });

              this.sku.price = skuValue.price;
              this.sku.stock_num = skuValue.stock;
            }
          }
          this.sku.list = list;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optOrder);
  },

  methods: {
    handleEditAddress() {
      this.showEditAdr = true;
    },

    onBuyClick(skuData) {
      this.skuData = skuData;
      this.skuType = true;
      this.showSku = false;
      this.skuData.selectedSkuComb.price = parseFloat(
        (this.skuData.selectedSkuComb.price / 100).toPrecision(12)
      );
    },

    onAdd() {
      this.showAddAdr = true;
    },

    onEdit(item) {
      this.showEdit = true;
      this.infoData = item;
      this.infoData.addressDetail = this.infoData.address;
      // console.log();
    },

    onChangeDetail(val) {
      console.log(val);
      if (val) {
      }
    },

    onSaveEdit(content) {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }

      // let md5Code = `${new Date().getTime()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
      // console.log(md5Code);

      let md5Code = `${content.addressDetail}${content.id}${content.city}${
        content.name
      }${content.county}${content.tel}${content.province}${
        content.userId
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;
      console.log(md5Code);

      const opt = {
        url: reqUrl.mallUpdateAddress,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          wallet: localStorage.getItem("userName"),
          token: localStorage.getItem("token"),
        },
        params: {
          address: content.addressDetail,
          addressId: content.id,
          city: content.city,
          contacts: content.name,
          district: content.county,
          number: content.tel,
          province: content.province,
          userId: content.userId,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.$Message.success("修改地址成功");
            this.refresh();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);

      let md5Code2 = `${content.id}${
        content.isDefault
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

      const optDefault = {
        url: reqUrl.mallSetDefaultAddress,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code2),
          wallet: localStorage.getItem("userName"),
          token: localStorage.getItem("token"),
        },
        params: {
          itemId: content.id,
          toDefault: content.isDefault,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(optDefault);
    },

    handleSubAdr(item) {
      this.currentContact = item;
      this.showEditAdr = false;
    },

    onSave(content) {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }
      let md5Code = `${content.addressDetail}${content.city}${content.name}${
        content.county
      }${content.tel}${
        content.province
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

      const opt = {
        url: reqUrl.mallAaddAddress,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          wallet: localStorage.getItem("userName"),
          token: localStorage.getItem("token"),
        },
        params: {
          address: content.addressDetail,
          city: content.city,
          contacts: content.name,
          district: content.county,
          number: content.tel,
          province: content.province,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.$Message.success("添加地址成功");
            this.refresh();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    onDelete(content) {
      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }

      let md5Code = `${
        content.id
      }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

      const opt = {
        url: reqUrl.mallDeleteAddress,
        method: "POST",
        headers: {
          nonce: num,
          ts: this.dateNew,
          sign: md5(md5Code),
          wallet: localStorage.getItem("userName"),
          token: localStorage.getItem("token"),
        },
        params: {
          itemId: content.id,
        },
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.refresh();
            this.$Message.success("删除成功");
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleGoBuy() {
      if (!this.skuType) {
        this.$Message.error("请选择您要购买的商品");
      } else {
        var num = "";
        for (var i = 0; i < 32; i++) {
          num += Math.floor(Math.random() * 10);
        }

        let md5Code = `${this.chosenAddressId * 1}${
          this.payData.detailDTO.goodsId * 1
        }${this.skuData.selectedNum * 1}${this.code}${
          this.skuData.selectedSkuComb.id * 1
        }${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

        const opt = {
          url: reqUrl.mallOrderAdd,
          method: "POST",
          headers: {
            nonce: num,
            ts: this.dateNew,
            sign: md5(md5Code),
            wallet: localStorage.getItem("userName"),
            token: localStorage.getItem("token"),
          },
          params: {
            addressId: this.chosenAddressId * 1,
            goodsId: this.payData.detailDTO.goodsId * 1,
            goodsNum: this.skuData.selectedNum * 1,
            inviteCode: this.code,
            skuId: this.skuData.selectedSkuComb.id * 1,
          },
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.$router.push("/vaitPayOrder");
              localStorage.setItem(
                "addPayOrderData",
                JSON.stringify(res.data.data)
              );
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

/deep/ .van-sku-row__image-item {
  margin: 0 18px 4px 0;
}
</style>

<style>
.el-notification {
  z-index: 11111 !important;
}
</style>
