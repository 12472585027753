<template>
  <div class="box_wallet" :style="concatShow">
    <Header></Header>
    <div class="video">
      <video :src="videoUrl" controls></video>
    </div>

    <div class="what_box" @click="goWhatBox">
      <p>什么是趣宝盒?</p>
      <img src="../../assets/boxWallet/right.png" alt="" />
    </div>

    <div class="txt_title">
      <h2>趣宝盒预售启动，抓住早鸟优惠， 开启智能出行新纪元！</h2>
      <p>
        趣宝盒，结合时尚与智能的驾驶伴侣，现正式开启2024年度的预售活动！加入我们，体验更智慧、更安全、更环保的驾驶旅程。赶快预订，享受早鸟特惠！
      </p>
    </div>

    <div class="rule">
      <h2>预售规则</h2>
      <div>
        <p>预售时间</p>
        <span>2024年全年，分11期进行</span>
      </div>
      <div>
        <p>预售方式</p>
        <span>通过App或链接钱包即可预订</span>
      </div>
      <div>
        <p>自动开启</p>
        <span>每满一期，自动开启下一期</span>
      </div>
    </div>

    <div class="plan">
      <h2>预售计划</h2>
      <div class="plan_all">
        <span>总进度</span>
        <el-progress
          :text-inside="true"
          :stroke-width="24"
          :percentage="overallInfo.percentCompleteAll"
          status="success"
        ></el-progress>
        <span>{{ overallInfo.quantityTotalAll }}台</span>
      </div>
      <div
        :class="[item.percentComplete < 100 ? 'plan_monLight' : 'plan_mon']"
        v-for="(item, i) in percentageData.slice(0, 3)"
        :key="i"
      >
        <div class="plan_month">
          <div class="plan_month_txt">
            <span class="plan_month_txt_l">{{ item.month }}月</span
            ><span>发货</span>
          </div>
          <el-progress
            :text-inside="true"
            :stroke-width="24"
            :percentage="item.percentComplete"
            status="success"
            :format="format"
          ></el-progress>
          <span>{{ item.quantityTotal }}台</span>
        </div>
        <p>
          Cple预期价/Cple当前价格：{{ item.cpleExpectedPrice }}/{{
            item.cpleRealPrice
          }}
        </p>
      </div>

      <div class="plan_see_all">
        <el-button type="info" round @click="showPopupAllPlan"
          >查看全部</el-button
        >

        <van-popup v-model="showAllPlan" position="top" closeable round>
          <div>
            <h2>预售计划</h2>
            <div class="plan_all">
              <span>总进度</span>
              <el-progress
                :text-inside="true"
                :stroke-width="24"
                :percentage="overallInfo.percentCompleteAll"
                status="success"
              ></el-progress>
              <span>{{ overallInfo.quantityTotalAll }}台</span>
            </div>
            <div
              :class="[
                item.percentComplete < 100 ? 'plan_monLight' : 'plan_mon',
              ]"
              v-for="(item, i) in percentageData"
              :key="i"
            >
              <div class="plan_month">
                <div class="plan_month_txt">
                  <span class="plan_month_txt_l">{{ item.month }}月</span
                  ><span>发货</span>
                </div>
                <el-progress
                  :text-inside="true"
                  :stroke-width="24"
                  :percentage="item.percentComplete"
                  status="success"
                  :format="format"
                ></el-progress>
                <span>{{ item.quantityTotal }}台</span>
              </div>
              <p>
                Cple预期价/Cple当前价格：{{ item.cpleExpectedPrice }}/{{
                  item.cpleRealPrice
                }}
              </p>
            </div>
          </div>
        </van-popup>
      </div>
      <div class="plan_see_all_txt">
        每个月的预售数量有所增加，CPLE的价值预期也将随之上涨。越早预订趣宝盒，您将享受到更大的潜在价值增长！
      </div>
    </div>

    <div class="what_now" @click="showPopupNow">
      <img src="../../assets/boxWallet/img1.png" @click="showPopupNow" alt="" />
    </div>

    <van-popup v-model="showNow" closeable round>
      <div class="now_show">
        <h2>为何现在就预订？</h2>
        <p>早鸟优惠： 越早预订，越早拿到趣宝盒。</p>
        <p>
          潜在价值增长： 随着CPLE价值预期上涨，早期预订将带来更高的潜在回报。
        </p>
        <p>与趣出行完美搭配： 专为顺风车服务设计，提升您的驾驶体验。</p>
        <p>全方位驾驶伴侣： 时尚外观、智能功能、全程语音陪护，应有尽有。</p>
        <p>🛍️ 立即行动，成为智能出行的先行者！</p>
        <p>
          趣宝盒不仅是您智能出行的伴侣，更是您智能投资的选择。错过今天，您可能会失去明天的机遇。立即预订趣宝盒，加入我们的智能出行革命！
        </p>
      </div>
    </van-popup>

    <div class="bottom_btn" @click="handlePay">
      <img src="../../assets/boxWallet/bottom_btn.png" alt="" />
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "./header.vue";
import Bottom from "./bottom.vue";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import md5 from "js-md5";

export default {
  components: { Header, Bottom },
  data() {
    return {
      showAllPlan: false, // 查看全部预售计划
      showNow: false, // 为啥现在就预定弹窗
      percentageShow: true, // 进度条style
      overallInfo: {},
      concatShow: {
        padding: "160px 20px 80px 20px",
      },
      planData: [
        {
          jindu: 100,
          count: 1000,
        },
        {
          jindu: 100,
          count: 1000,
        },
        {
          jindu: 100,
          count: 1000,
        },
        {
          jindu: 50,
          count: 2000,
        },
      ],

      videoUrl: "",
      percentageData: [],
      dateNew: "",
    };
  },

  mounted() {
    var num = "";
    for (var i = 0; i < 32; i++) {
      num += Math.floor(Math.random() * 10);
    }

    let md5Code = `${this.getTimeFn()}${num}cde89da976c98e74f156e5cf40f9dbf3`;

    const opt = {
      url: reqUrl.getPresaleProgress,
      method: "POST",
      headers: {
        nonce: num,
        sign: md5(md5Code),
        ts: this.dateNew,
        wallet: localStorage.getItem("userName"),
      },
      params: {},
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.videoUrl = res.data.data.videoUrl;
          this.percentageData = res.data.data.progressList;
          this.overallInfo = res.data.data.overallInfo;
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    format(percentage) {
      return percentage == 0 ? "未开始" : `${percentage}%`;
    },

    handlePay() {
      this.$router.push("/boxWalletOrder");
    },

    showPopupAllPlan() {
      this.showAllPlan = true;
    },

    showPopupNow() {
      this.showNow = true;
    },

    goWhatBox() {
      this.$router.push("/whatBox");
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
