<template>
  <div class="register_msg">
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
      <Form-item prop="name">
        <el-select
          v-model="formValidate.valueArea"
          filterable
          placeholder="请选择"
          style="width: 120px"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :value="item.dial_code"
          >
            <div style="display: flex; align-items: center">
              <img
                :src="item.url"
                alt=""
                style="width: 34px; height: 22px; margin-right: 20px"
              />
              {{ item.dial_code }} {{ item.name }}
            </div>
          </el-option>
        </el-select>

        <Input
          v-model="formValidate.name"
          :placeholder="$t('register.inputPhone')"
        ></Input>
      </Form-item>
      <FormItem v-if="flag" prop="pass">
        <Input
          type="password"
          :placeholder="$t('register.inputPass')"
          v-model="formValidate.pass"
          style="width: 90%"
        />
        <i class="iconfont icon-biyanjing" @click="handlePassword"></i>
      </FormItem>

      <FormItem v-else prop="pass">
        <Input
          type="text"
          :placeholder="$t('register.inputPass')"
          v-model="formValidate.pass"
          style="width: 90%"
        />
        <i class="iconfont icon-yanjing" @click="handlePassword"></i>
      </FormItem>

      <FormItem prop="code">
        <Input
          type="text"
          :placeholder="$t('register.inputSMSCode')"
          :maxlength="6"
          v-model="formValidate.code"
          style="width: 73%"
        />
        <Button style="border: none; box-shadow: none">
          <p v-show="timerTf" @click="handleSubmit('formValidate')">
            {{ $t("register.inputCode") }}
          </p>
          <p v-show="!timerTf" style="color: #ccc">{{ countDownNum }} s</p>
        </Button>
      </FormItem>

      <div class="cple_btn">
        <Button
          type="primary"
          shape="circle"
          @click="handleCancel('formValidate')"
          >注销用户</Button
        >
      </div>
    </Form>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { areaCodeData } from "@/util/areaCodeData";

import md5 from "js-md5";
export default {
  data() {
    const phoneCheck = async (rule, value, callback) => {
      let regPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (value.length == 0) {
        return callback(new Error(this.$t("register.txt3")));
      } else if (!regPhone.test(value)) {
        return callback(new Error(this.$t("register.txt4")));
      }
    };
    // 密码验证
    const pwdCheck = async (rule, value, callback) => {
      let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (value.length < 8) {
        return callback(new Error(this.$t("register.txt5")));
      } else if (value.length > 15) {
        return callback(new Error(this.$t("register.txt6")));
      } else if (!reg.test(value)) {
        return callback(new Error(this.$t("register.txt7")));
      } else {
        callback();
      }
    };

    return {
      formValidate: {
        valueArea: "+86",
        name: "",
        pass: "",
        code: "",
      },
      options: [],

      flag: true,
      ruleValidate: {
        name: [{ required: true, validator: phoneCheck, trigger: "blur" }],
        pass: [{ required: true, validator: pwdCheck, trigger: "blur" }],
      },

      timerTf: true,
      countDownNum: 60,
      dateNew: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    if (this.$route.query.language == "zh-CN") {
      this.$store.state.isLang = "zh-CN";
      localStorage.setItem("language", "zh-CN");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "en") {
      this.$store.state.isLang = "en";
      localStorage.setItem("language", "en");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ja") {
      this.$store.state.isLang = "ja";
      localStorage.setItem("language", "ja");
      this.$i18n.locale = localStorage.getItem("language");
    } else if (this.$route.query.language == "ko") {
      this.$store.state.isLang = "ko";
      localStorage.setItem("language", "ko");
      this.$i18n.locale = localStorage.getItem("language");
    }
  },

  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.varify();
        } else {
          this.$Message.error(this.$t("register.txt8"));
        }
      });
    },

    handlePassword() {
      this.flag = !this.flag;
    },

    handleCancel(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          var num = "";
          for (var i = 0; i < 32; i++) {
            num += Math.floor(Math.random() * 10);
          }

          let md5Code = `${this.formValidate.pass}${this.formValidate.name}${
            this.formValidate.valueArea
          }${
            this.formValidate.code
          }${this.getTimeFn()}${num}18A626260181FCF6B6B5929B5BCC3B0F7D4CF5F4`;
          console.log(md5Code);

          this.$Spin.show();
          const opt = {
            url: reqUrl.cancelUrl,
            method: "POST",
            headers: {
              "Content-Type":
                "application/json; charset=utf-8;responseType=arraybufferimage.png",
              ts: this.dateNew,
              nonce: num,
              sign: md5(md5Code),
            },
            params: JSON.stringify({
              phone: this.formValidate.name,
              phoneArea: this.formValidate.valueArea,
              password: this.formValidate.pass,
              smsCode: this.formValidate.code,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 1403 || res.data.code != 0) {
                this.$Message.error(res.data.message);
                this.$Spin.hide();
              }
              this.$Spin.hide();
            },
            errFunc: (err) => {
              console.log(err, "err1");
              this.$Spin.hide();
            },
          };
          request(opt);
        } else {
          this.$Message.error(this.$t("register.txt8"));
        }
      });
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    clearTime() {
      this.timerTf = true;
      clearInterval(this.timer);
    },

    receiveCode() {
      this.$refs["formValidate"].validate((valid) => {
        if (!valid) {
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },

    varify() {
      this.$Captcha.show();
      this.$bus.$on("getTicket", (res) => {
        if (res.ret === 0) {
          var num = "";
          for (var i = 0; i < 32; i++) {
            num += Math.floor(Math.random() * 10);
          }

          var num2 = "";
          for (var i = 0; i < 6; i++) {
            num2 += Math.floor(Math.random() * 10);
          }

          let md5Code = `${this.formValidate.name}${
            this.formValidate.valueArea
          }${num2}1${
            res.ticket
          }${this.getTimeFn()}${num}18A626260181FCF6B6B5929B5BCC3B0F7D4CF5F4`;
          console.log(md5Code);

          const opt = {
            url: reqUrl.sendCode,
            method: "POST",
            headers: {
              "Content-Type":
                "application/json; charset=utf-8;responseType=arraybufferimage.png",
              ts: this.dateNew,
              nonce: num,
              sign: md5(md5Code),
            },
            params: JSON.stringify({
              phone: this.formValidate.name,
              phoneArea: this.formValidate.valueArea,
              rand: num2,
              scene: 1,
              ticket: res.ticket,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.countDownNum = 60;
                this.timerTf = false;
                this.timer = setInterval(() => {
                  if (this.countDownNum > 0 && this.countDownNum <= 60) {
                    this.countDownNum--;
                  } else {
                    this.timerTf = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
              if (res.data.code != 0) {
                this.$notify.error(res.data.message);
              }
            },
            errFunc: (err) => {
              console.log(err, "err1");
            },
          };
          request(opt);
        } else {
          this.$notify.warning(this.$t("register.txt9"));
        }
        this.$bus.$off("getTicket");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register_msg {
  width: calc(100% - 40px);
  margin: 0 auto;

  /deep/ .ivu-input {
    height: 50px;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    font-size: 14px;
  }
  /deep/ .ivu-form-item-content {
    border: 1px #ebedf2 solid;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input__inner {
      border: none;
    }

    i {
      margin-right: 10px;
    }

    p {
      color: #5778ff;
      font-weight: 500;
      font-size: 15px;
      margin-right: 10px;
    }
  }
}
</style>
