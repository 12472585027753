<template>
  <div>
    <div class="header">
      <div class="header_l">
        <img
          class="header_img"
          src="../../assets/boxWallet/header_txt.png"
          alt=""
        />
        <div class="header_r">
          <el-button type="info" round @click="connectWallet">
            <i class="iconfont icon-lianjie"></i>
            <span>连接钱包</span>
          </el-button>
          <i
            class="iconfont icon-santiaogang"
            style="color: #fff"
            @click="showPopup"
          ></i>
        </div>
      </div>
      <div class="concat" v-if="concatShow">
        <p class="concat_txt">{{ userName }}</p>
        <div class="concat_info">
          <p>{{ userData.userName }}</p>
          <div class="concat_info_mi">
            <!-- <p>78 Cple积分</p> -->
            <p>{{ userData.quota }} 转出额度</p>
            <p>{{ userData.balance }} 余额</p>
          </div>
          <img src="../../assets/boxWallet/no.png" @click="handleOut" alt="" />
        </div>

        <van-popup v-model="showOut" round>
          <div class="now_show">
            <h2>确定解除钱包连接吗？</h2>
            <div>
              <el-button type="info" round @click="showOut = false"
                >取消</el-button
              >
              <el-button round @click="handleOutConcat">解除连接</el-button>
            </div>
          </div>
        </van-popup>
      </div>
    </div>

    <van-popup v-model="show" position="top" closeable round>
      <img
        class="header_img"
        src="../../assets/boxWallet/header_txt.png"
        alt=""
      />
      <div class="header_b" @click="handleGoPage('home')">首页</div>
      <div class="header_b" @click="handleGoPage('whatBox')">产品介绍</div>
      <div class="header_b" @click="handleGoPage('meOrder')">我的订单</div>

      <div class="header_lang">
        <p>语言</p>
        <div :class="[localShow ? 'localShow' : 'localShow2']">
          简体中文
          <van-icon name="success" />
        </div>
        <div :class="[!localShow ? 'localShow' : 'localShow2']">繁体中文</div>
        <div :class="[!localShow ? 'localShow' : 'localShow2']">English</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Web3 from "web3";

import { showBalance, signMessage } from "../../service/mall_mask";
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import axios from "axios";

export default {
  inject: ["reload"],
  data() {
    return {
      show: false,
      showOut: false,
      localShow: true, // 下拉菜单语言style
      concatShow: false, // 连接成功

      web3: null,
      connected: false,
      message: "",
      signature: "",
      userName: "",
      balance: "",

      userData: {},
      dateNew: "",
    };
  },

  created() {
    this.userName = localStorage.getItem("userName");
    this.balance = localStorage.getItem("balance");
    this.concatShow = localStorage.getItem("concatShow");
    if (this.userName) {
      localStorage.setItem("concatShow", false);
    }
  },

  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  },

  methods: {
    async connectWallet() {
      if (!window.ethereum) {
        this.$Message.error("请安装MetaMask");
        return;
      }

      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        this.web3 = new Web3(window.ethereum);
        this.connected = true;
        console.log("已连接钱包");
        showBalance();
        this.balance = localStorage.getItem("balance");
        // signMessage(this.connected, this.web3);
        this.signMessage();
        this.refresh();
      } catch (error) {
        console.error(error);
      }
    },

    // 签名
    async signMessage() {
      if (!this.connected) {
        this.$Message.error("请先连接到钱包");
        return;
      }

      const address = (await this.web3.eth.getAccounts())[0];
      localStorage.setItem("userName", address);

      var num = "";
      for (var i = 0; i < 32; i++) {
        num += Math.floor(Math.random() * 10);
      }

      this.dateNew = new Date().getTime();
      let nonce = `${this.dateNew}${num}qcxPresale`;
      this.message = nonce;

      const signature = await this.web3.eth.personal.sign(
        this.message,
        address
      );
      this.signature = signature;

      localStorage.setItem("sign", signature);
      localStorage.setItem("concatShow", true);

      if (this.signature != "") {
        this.userInfoAxios({
          ts: this.dateNew,
          sign: this.signature,
          num: num,
        });
      }
      this.refresh();
    },

    userInfoAxios(obj) {
      axios
        .post(
          reqUrl.userBasicGet,
          {},
          {
            headers: {
              nonce: obj.num,
              ts: obj.ts,
              sign: obj.sign,
              wallet: localStorage.getItem("userName"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("userData", JSON.stringify(res.data.data));
            localStorage.setItem("token", JSON.stringify(res.data.data.token));
            this.refresh();
          } else {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        });
    },

    showPopup() {
      this.show = true;
    },

    handleOut() {
      this.showOut = true;
    },

    handleOutConcat() {
      this.showOut = false;
      localStorage.removeItem("userName");
      localStorage.removeItem("sign");
      localStorage.removeItem("balance");
      localStorage.removeItem("concatShow");
      this.refresh();
    },

    handleGoPage(page) {
      if (page == "home") {
        this.$router.push("/boxWallet");
      } else if (page == "whatBox") {
        this.$router.push("/whatBox");
      } else if (page == "meOrder") {
        this.$router.push("/meOrder");
      }
    },

    getTimeFn() {
      this.dateNew = new Date().getTime();
      return this.dateNew;
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 96%;
  background: rgba(29, 31, 41, 1);
  position: fixed;
  top: 10px;
  left: 10px;
  border-radius: 24px;
  padding: 10px 20px;
  z-index: 11;

  .header_l {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .concat {
    margin-top: 7px;
    border-top: 1px #ffffff26 solid;
    padding-top: 8px;

    .concat_txt {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff66;
    }

    img {
      width: 48px;
      height: 36px;
    }

    .concat_info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .concat_info_mi {
        display: flex;
        align-items: center;

        p {
          margin-right: 10px;
        }
      }
    }
  }
}

.header_img {
  width: 115px;
  height: 14px;
}

.van-popup--top {
  background: rgba(29, 31, 41, 1);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;

  /deep/ .van-popup__close-icon--top-right {
    top: 30px;
  }

  .header_b {
    width: 100%;
    height: 50px;
    border-bottom: 1px #696969 solid;
    text-align: center;
    line-height: 50px;
  }

  img {
    margin-top: 20px;
  }

  .header_lang {
    margin-top: 20px;

    p {
      color: #a4a1a1;
      margin-bottom: 10px;
    }

    .localShow {
      color: #fff;
    }
    .localShow2 {
      color: #9d9d9d;
    }

    div {
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.el-button--info {
  background: #28292e;
  border-color: #28292e;
  margin-right: 10px;

  i {
    margin-right: 5px;
  }
}

.el-button.is-round {
  padding: 12px 10px;
}

.van-popup--center {
  width: 80%;
  background: #1d1f29;

  .now_show {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 20px;
    }

    .el-button {
      width: 100px;
    }

    .el-button:focus,
    .el-button:hover {
      color: #f3f3f3;
      border-color: #3c3c3c;
      background-color: #606060;
    }
  }
}
</style>
