<template>
  <div>
    <van-sku
      v-model="show"
      :sku="sku"
      :goods="goods"
      :goods-id="skuData.goodsId"
      @buy-clicked="onBuyClick"
      :show-add-cart-btn="false"
      buy-text="确认"
    >
    </van-sku>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "颜色", // 规格名
            k_s: "s1", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [
              {
                id: 1, // skuId
                name: "红色", // 规格值
                imgUrl: "https://cdn.quchuxing.com/i/u/170606306748290063",
                previewImgUrl:
                  "https://cdn.quchuxing.com/i/u/170606306748290063",
              },
              {
                id: 2,
                name: "蓝色",
                imgUrl: "https://cdn.quchuxing.com/i/u/170606310225074215",
                previewImgUrl:
                  "https://cdn.quchuxing.com/i/u/170606310225074215",
              },
            ],
            largeImageMode: true, //  是否展示大图模式
          },
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          {
            id: 22, // skuId
            s1: "1", // 规格类目 k_s 为 s1 的对应规格值 id
            price: 12, // skuPrice
            stock_num: 10, // skuStock
          },
          {
            id: 33,
            s1: "2",
            price: 1200,
            stock_num: 20,
          },
        ],
        price: "120", // skuPrice
        stock_num: 1000, // 商品总库存
      },
      goods: {
        picture: "https://cdn.quchuxing.com/i/u/170606306748290063",
      },
      skuData: {
        // 商品 id
        goodsId: "1",
        // 选择的商品数量
        selectedNum: 1,
        // 选择的 sku 组合
        selectedSkuComb: {},
      },
    };
  },

  methods: {
    onBuyClick(skuData) {
      console.log(skuData, 999);
    },
  },
};
</script>

<style></style>
